import React, { useEffect } from 'react'
import { useSettingStore } from '../store';


export default function DarkMode() {

    const { mode, setMode } = useSettingStore((state) => ({
        mode: state.darkMode,
        setMode: state.toggleDarkMode,
    }));

    const toggleThemeMode = () => {
        const htmlElem = document.getElementById('root');
        if (mode) {
            htmlElem.classList.remove("dark");
            setMode(!mode);
        } else {
            htmlElem.classList.add("dark");
            setMode(!mode);
        }
    }

    useEffect(() => {
        if (mode) {
            const htmlElem = document.getElementById('root');
            htmlElem.classList.add("dark");
        }
        return () => { }
    }, [])


    return (
        <div className='mt-2 md:mt-0'>
            {
                !mode ?
                    (
                        <button onClick={toggleThemeMode} className='outline-0'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 dark:fill-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                            </svg>
                        </button>
                    )
                    :
                    (
                        <button onClick={toggleThemeMode} className='outline-0'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 dark:fill-white dark:stroke-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                            </svg>
                        </button>
                    )
            }
        </div >
    )
}
